import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from "react";
import { navigate } from 'gatsby'
import { Form, FormGroup, FormFeedback, Input, Row, Col, Button, Label, FormText } from 'reactstrap'
import { isLoggedIn, handleRegistration } from '../services/auth'
import { CountryDropdown } from 'react-country-region-selector'
import validator from 'validator'
import ReCAPTCHA from "react-google-recaptcha";


import formStyles from '../components/styles/form.module.css'
import "../components/styles/layout.css";
import { CODE_SIGN_UP, CODE_COGNITO } from "../components/authcode";


export default class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            orgName: "",
            contact: "",
            country: "",
            email: "",
            events: 0,
            api: 0,
            emailValid: false,
            passValid: false,
            confirmValid: false,
            firstValid: false,
            lastValid: false,
            contactValid: false,
            orgValid: false,
            countryValid: false,
            formValid: false,
            formErrors: {
                email: false,
                password: false,
                confirmPassword: false,
                firstName: false,
                lastName: false,
                contact: false,
                country: false,
                org: false
            },
            respErrors: {
                email: {
                    isValid: true,
                    message: ""
                },
                password: {
                    isValid: true,
                    message: ""
                },
                phone_number: {
                    isValid: true,
                    message: ""
                }
            },
            isLoading: false,
            captcha: "",
            recaptcha: true,
        }
    }

    componentDidMount() {
        if (isLoggedIn()) {
            navigate(`/`)
            if (typeof window !== "undefined") {
                window.location.replace('/')
            }
        }
    }

    selectCountry = val => {
        const name = 'country';
        const value = val;
        this.setState({ [name]: value },
            () => {
                this.validateField(name, value)
            }
        );
    }

    handleCheckbox = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateField(field, value) {
        let password = this.state.password;
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passValid = this.state.passValid;
        let confirmPassword = this.state.confirmPassword
        let confirmValid = this.state.confirmValid;
        let firstValid = this.state.firstValid;
        let lastValid = this.state.lastValid;
        let contactValid = this.state.contactValid;
        let orgValid = this.state.orgValid;
        let countryValid = this.state.countryValid;
        let fieldValidationMessages = this.state.respErrors;

        switch (field) {
            case 'email':
                emailValid = validator.isEmail(value);
                fieldValidationErrors.email = emailValid ? false : true;
                fieldValidationMessages.email.isValid = true;
                fieldValidationMessages.email.message = "Invalid E-Mail Address";
                break;

            case 'password':

                fieldValidationMessages.password.message = passValid ? '' : undefined;

                if (confirmValid) {
                    if (!validator.equals(value, confirmPassword)) {
                        fieldValidationMessages.password.message = "The passwords you entered do not match"
                    } else {
                        fieldValidationMessages.password.message = ""
                    }
                }

                if (!validator.matches(value, RegExp('^(?=.*[A-Z])'))) {
                    fieldValidationMessages.password.message = "At least 1 upper case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[a-z])'))) {
                    fieldValidationMessages.password.message = "At least 1 lower case character is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[0-9])'))) {
                    fieldValidationMessages.password.message = "At least 1 number is required";
                }

                if (!validator.matches(value, RegExp('^(?=.*[!@#$%^&*])'))) {
                    fieldValidationMessages.password.message = "At least 1 symbol is required";
                }

                if (!validator.matches(value, RegExp('^(?=.{14,})'))) {
                    fieldValidationMessages.password.message = "Password must have at least 14 characters";
                }

                if (validator.isEmpty(value)) {
                    fieldValidationMessages.password.message = "Password must not be empty";
                }

                passValid = (validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && validator.matches(confirmPassword, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})')) && !validator.isEmpty(value) && !validator.isEmpty(confirmPassword))
                fieldValidationErrors.password = passValid ? false : true;
                break;

            case 'confirmPassword':
                confirmValid = validator.equals(value, password) && !validator.isEmpty(password) && validator.matches(value, RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{14,})'));
                fieldValidationErrors.confirmPassword = confirmValid ? false : true;
                fieldValidationErrors.password = confirmValid ? false : true;
                fieldValidationMessages.password.message = confirmValid ? "" : "The passwords you entered do not match"
                passValid = confirmValid
                break;

            case 'firstName':
                firstValid = !validator.isEmpty(value) && this.isASCII(value)
                if (this.isASCII(value)) {
                    this.setState({
                        firstNameMessage: "Please fill up your First name"
                    })
                } else {
                    this.setState({
                        firstNameMessage: "Invalid characters used"
                    })
                }

                fieldValidationErrors.firstName = firstValid ? false : true;
                break;

            case 'lastName':
                lastValid = !validator.isEmpty(value) && this.isASCII(value)
                if (this.isASCII(value)) {
                    this.setState({
                        lastNameMessage: "Please fill up your Last name"
                    })
                } else {
                    this.setState({
                        lastNameMessage: "Invalid characters used"
                    })
                }
                fieldValidationErrors.lastName = lastValid ? false : true;
                break;

            case 'contact':
                contactValid = (validator.isNumeric(value, { no_symbols: false }) && (validator.matches(value, RegExp('^[+]{1}'))));
                fieldValidationErrors.contact = contactValid ? false : true;
                break;

            case 'country':
                countryValid = validator.isISO31661Alpha2(value);
                fieldValidationErrors.country = countryValid ? false : true;
                break;

            case 'orgName':
                orgValid = !validator.isEmpty(value) && this.isASCII(value)
                if (this.isASCII(value)) {
                    this.setState({
                        orgNameMessage: "Please indicate your organization name"
                    })
                } else {
                    this.setState({
                        orgNameMessage: "Invalid characters used"
                    })
                }
                fieldValidationErrors.org = orgValid ? false : true;
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passValid: passValid,
            confirmValid: confirmValid,
            firstValid: firstValid,
            lastValid: lastValid,
            contactValid: contactValid,
            countryValid: countryValid,
            orgValid: orgValid
        }, this.validateForm)
    }

    validateForm() {
        this.setState({
            formValid:
                this.state.emailValid && this.state.confirmValid && this.state.contactValid && this.state.passValid && this.state.firstValid && this.state.lastValid && this.state.contactValid && this.state.confirmValid && this.state.orgValid && !!this.state.captcha,
        })
    }

    // Handle value change for the input and save it into the state
    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        },
            () => {
                this.validateField(name, value)
            }
        );
    }

    captchaChange = (value) => {
        const token = value

        this.setState({
            captcha: token
        }, () => {
            this.validateForm()
        })
    }

    isASCII(str) {
        return RegExp('^[\x00-\x7F]*$').test(str);
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        console.log('-- Form Sign Up --');
        this.setState({
            isLoading: true
        })
        await handleRegistration(this.state)
            .then(async (res) => {
                switch (res.code) {
                    case CODE_SIGN_UP.USER_EXISTS:
                        res.message = "An account with the given email already exists"

                        this.setState({
                            respErrors: {
                                email: {
                                    isValid: false,
                                    message: res.message
                                },
                                phone_number: {
                                    isValid: true,
                                    message: ""
                                },
                                password: {
                                    isValid: true,
                                    message: ""
                                }
                            },
                            isLoading: false
                        }, this.validateForm)
                        break

                    case CODE_COGNITO.NETWORK_ERROR:
                        res.message = "Network Error. Please check your internet connection."
                        this.setState({
                            respErrors: {
                                email: {
                                    isValid: false,
                                    message: res.message
                                },
                                phone_number: {
                                    isValid: true,
                                    message: ""
                                },
                                password: {
                                    isValid: true,
                                    message: ""
                                }
                            },
                            isLoading: false
                        }, this.validateForm)
                        break;

                    // PHONE NUMBER FORMAT ERROR
                    case CODE_SIGN_UP.ATTRIBUTE_ERROR:

                        this.setState({
                            respErrors: {
                                phone_number: {
                                    isValid: false,
                                    message: res.message
                                },
                                password: {
                                    isValid: true,
                                    message: ""
                                },
                                email: {
                                    isValid: true,
                                    message: ""
                                }
                            },
                            isLoading: false
                        }, this.validateForm)
                        break

                    case CODE_COGNITO.PROCESS_VALID:
                        navigate('/confirmation/')
                        break;


                    default:
                        res.message = "Something went wrong. Contact the administrator if this message appears."

                        this.setState({
                            isLoading: false
                        })
                        break;
                }

            })
            .catch(err => console.log(err))
    }

    render() {
        const { country } = this.state;

        return (

            <Row >
                <Col md={{ size: 8, offset: 2 }} className={formStyles.signupContainer}>
                    <Col sm="12" md="12" >
                        <Form onSubmit={this.handleSubmit} >
                            <FormText className="text-center">
                                <i>All fields are mandatory unless otherwise stated</i><br />
                                <i>Please note details updated on this portal will not be updated on your NETS merchant profile.</i>
                            </FormText>
                            <h2 style={{
                                fontWeight: 'bolder',
                                textAlign: "center"
                            }}>
                                Create a NETS Developer Account
                            </h2>

                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="firstName">First Name</Label>
                                        <Input
                                            autoFocus
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            required
                                            value={this.state.firstName.value}
                                            onChange={this.handleChange}
                                            onBlur={this.handleChange}
                                            className={formStyles.signUp}
                                            valid={this.state.firstValid}
                                            invalid={this.state.formErrors.firstName ? true : undefined}
                                        />
                                        <FormFeedback invalid={this.state.formErrors.firstName ? '' : undefined}>
                                            {this.state.firstNameMessage}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="lastName">Last Name</Label>
                                        <Input
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            required
                                            value={this.state.lastName.value}
                                            onChange={this.handleChange}
                                            onBlur={this.handleChange}
                                            className={formStyles.signUp}
                                            valid={this.state.lastValid}
                                            invalid={this.state.formErrors.lastName ? true : undefined}
                                        />
                                        <FormFeedback invalid={this.state.formErrors.lastName ? '' : undefined}>
                                            {this.state.lastNameMessage}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    id="email"
                                    type="email"
                                    name="email"
                                    required
                                    value={this.state.email.value}
                                    onChange={this.handleChange}
                                    onBlur={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.emailValid}
                                    invalid={(this.state.formErrors.email || !this.state.respErrors.email.isValid) ? true : undefined}
                                />
                                <FormFeedback invalid={this.state.respErrors.email.isValid ? '' : undefined}>
                                    {this.state.respErrors.email.message}
                                </FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label for="contact">Contact Number</Label>
                                <Input
                                    id="contact"
                                    type="text"
                                    name="contact"
                                    required
                                    value={this.state.contact.value}
                                    onChange={this.handleChange}
                                    onBlur={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.contactValid}
                                    invalid={this.state.formErrors.contact || !this.state.respErrors.phone_number.isValid ? true : undefined}
                                />
                                <FormFeedback
                                    invalid={this.state.formErrors.contact || !this.state.respErrors.phone_number.isValid ? '' : undefined}
                                >
                                    {!this.state.respErrors.phone_number.isValid ? (
                                        <p>
                                            Invalid phone number format. <br /> Please double check if the country code you have entered is correct
                                        </p>
                                    ) : (
                                            <p>
                                                Please provide a valid contact number.<br />
                                                Must include the country code (e.g. +65xxxxxxxx )
                                        </p>
                                        )}

                                </FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    id="password"
                                    type="password"
                                    name="password"
                                    required
                                    autoComplete="off"
                                    value={this.state.password.value}
                                    onChange={this.handleChange}
                                    onBlur={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.passValid}
                                    invalid={this.state.formErrors.password ? true : undefined}
                                    onDrop={(event) => { event.preventDefault() }}
                                    onCopy={(event) => { event.preventDefault() }}
                                    onPaste={(event) => { event.preventDefault() }}
                                />
                                <FormFeedback invalid={this.state.respErrors.password.isValid ? '' : undefined}>
                                    {this.state.respErrors.password.message}

                                </FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label for="confirmPassword">Confirm Password</Label>
                                <Input
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    required
                                    autoComplete="off"
                                    value={this.state.confirmPassword.value}
                                    onChange={this.handleChange}
                                    onBlur={this.handleChange}
                                    className={formStyles.signUp}
                                    valid={this.state.confirmValid}
                                    invalid={this.state.formErrors.confirmPassword ? true : undefined}
                                    onDrop={(event) => { event.preventDefault() }}
                                    onCopy={(event) => { event.preventDefault() }}
                                    onPaste={(event) => { event.preventDefault() }}
                                />
                                <FormFeedback
                                    invalid={this.state.formErrors.confirmPassword ? '' : undefined}
                                >
                                    The passwords you entered do not match
                            </FormFeedback>

                            </FormGroup>


                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="orgName">Company Name</Label>
                                        <Input
                                            id="orgName"
                                            type="text"
                                            name="orgName"
                                            value={this.state.lastName.value}
                                            onChange={this.handleChange}
                                            onBlur={this.handleChange}
                                            className={formStyles.signUp}
                                            required
                                            valid={this.state.orgValid}
                                            invalid={this.state.formErrors.org ? true : undefined}
                                        />
                                        <FormFeedback
                                            invalid={this.state.formErrors.org ? '' : undefined}
                                        >
                                            {this.state.orgNameMessage}
                                        </FormFeedback>
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="country" className="clearfix">Country</Label>
                                        <br className="d-md-block" />
                                        <CountryDropdown
                                            id="country"
                                            name="country"
                                            value={country}
                                            showDefaultOption={false}
                                            onChange={this.selectCountry}
                                            onBlur={this.handleChange}
                                            valueType="short"
                                            priorityOptions={['SG']}
                                            className={[formStyles.dropdownStyle, "form-control"].join(' ')}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" required />{' '}
                                    I accept all <a target="_blank" href="https://www.nets.com.sg/policies/terms-of-use" rel="noopener noreferrer">Terms & Conditions</a>
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="events" value={1} onChange={this.handleCheckbox} />{' '}
                                    Sign me up for Events Newsletter <i>(Optional)</i>
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="api" value={1} onChange={this.handleCheckbox} />{' '}
                                    Sign me up for API Updates <i>(Optional)</i>
                                </Label>
                            </FormGroup>
                            <br />

                            <ReCAPTCHA
                                sitekey={process.env.CAPTCHA_SITEKEY}
                                onChange={this.captchaChange}
                                ref={(r) => this.recaptcha = r}
                            />

                            {/* Desktop Display */}
                            <div className="float-right d-none d-md-block">
                                <Button color="link" className={formStyles.buttonStyles}
                                    onClick={
                                        (event) => {
                                            event.preventDefault()
                                            navigate('/signin/')
                                        }}>
                                    Cancel
                            </Button>

                                <Button
                                    color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
                                    className={formStyles.buttonStyles}
                                    disabled={!this.state.formValid || this.state.isLoading}
                                >
                                    SIGN UP
                            </Button>
                            </div>

                            <br className="d-md-none" />

                            {/* Mobile Display */}
                            <Button
                                color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')}
                                block
                                disabled={!this.state.formValid || this.state.isLoading}
                            >
                                SIGN UP
                        </Button>
                            <Button color="link"
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')} block
                                onClick={
                                    (event) => {
                                        event.preventDefault()
                                        navigate('/signin/')
                                    }}
                            >
                                Cancel
                        </Button>
                            <FormFeedback >

                            </FormFeedback>
                        </Form>
                    </Col>
                </Col>
            </Row>
        );
    }
}