import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import Signup from "../containers/signup";
import Layout from "../components/layout"
import { Container } from "reactstrap"

const signIn = () => {
  return (
    <Layout>

      <Container
        style={{
          padding: '2rem 1rem 2rem',
          minHeight: '75vh'
        }}
      >
        <Signup />
      </Container>
    </Layout>

  )
}

export default signIn
